import axios from "axios";
import headers from "./Headers";

function getConstellations() {
  return axios.get("constellations");
}

function createConstellation(form) {
  headers.contentType = "multipart/form-data";
  return axios.post("constellations", form, headers);
}

function showConstellation(id) {
  return axios.get(`constellations/${id}`, headers);
}

function editConstellation(id, form) {
  headers.contentType = "multipart/form-data";
  return axios.patch(`constellations/${id}`, form, headers);
}

function deleteConstellation(id) {
  return axios.delete(`constellations/${id}`, headers);
}

export {
  getConstellations,
  createConstellation,
  showConstellation,
  editConstellation,
  deleteConstellation,
};
