<template>
  <div class="section-content section-constellations">
    <div class="container-fluid">
      <div
        class="heading mb-4 d-flex align-items-center justify-content-between"
      >
        <div>
          <h2 class="font-semi-bold">Edit Constellation Page</h2>
          <p class="subtitle">Edit data constellation.</p>
        </div>

        <a @click.prevent="$router.back()" class="icon">
          <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2"
          >
            <path
              d="M1.4793 6.65259H16.2606C16.4567 6.65259 16.6446 6.73045 16.7832 6.86906C16.9218 7.00766 16.9997 7.19564 16.9997 7.39165C16.9997 7.58767 16.9218 7.77565 16.7832 7.91425C16.6446 8.05286 16.4567 8.13072 16.2606 8.13072H1.4793C1.28329 8.13072 1.0953 8.05286 0.956702 7.91425C0.8181 7.77565 0.740234 7.58767 0.740234 7.39165C0.740234 7.19564 0.8181 7.00766 0.956702 6.86906C1.0953 6.73045 1.28329 6.65259 1.4793 6.65259Z"
              fill="#101010"
            />
            <path
              d="M1.78543 7.3916L7.91525 13.5199C8.05403 13.6587 8.13199 13.8469 8.13199 14.0432C8.13199 14.2395 8.05403 14.4277 7.91525 14.5665C7.77647 14.7052 7.58825 14.7832 7.39199 14.7832C7.19573 14.7832 7.00751 14.7052 6.86873 14.5665L0.217129 7.91486C0.148302 7.84621 0.0936956 7.76465 0.0564371 7.67486C0.0191786 7.58507 0 7.48881 0 7.3916C0 7.29439 0.0191786 7.19813 0.0564371 7.10834C0.0936956 7.01855 0.148302 6.937 0.217129 6.86834L6.86873 0.216741C7.00751 0.0779639 7.19573 0 7.39199 0C7.58825 0 7.77647 0.0779639 7.91525 0.216741C8.05403 0.355518 8.13199 0.54374 8.13199 0.74C8.13199 0.936261 8.05403 1.12448 7.91525 1.26326L1.78543 7.3916Z"
              fill="#101010"
            />
          </svg>

          Back
        </a>
      </div>

      <div class="content">
        <div class="row mb-3">
          <div class="col">
            <div class="card card-form border-0">
              <div class="card-body">
                <form @submit.prevent="save" v-if="isLoaded">
                  <div class="row no-gutters">
                    <div class="col-md-12">
                      <div class="d-flex mb-2">
                        <img
                          v-lazy="`${urlImg}${image_light_temporary}`"
                          :alt="form.name"
                          width="150"
                          class="d-block mr-2"
                        />

                        <img
                          v-lazy="`${urlImg}${icon_light_temporary}`"
                          :alt="form.name"
                          width="150"
                          class="d-block"
                        />
                      </div>

                      <div class="form-group">
                        <label for="image_light">Image Light</label>

                        <b-form-file
                          v-model="form.image_light"
                          :state="Boolean(form.image_light)"
                          accept=".svg"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </div>

                      <div class="form-group">
                        <label for="image_dark">Image Dark</label>

                        <b-form-file
                          v-model="form.image_dark"
                          :state="Boolean(form.image_dark)"
                          accept=".svg"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </div>

                      <div class="form-group">
                        <label for="icon_light">Icon Light</label>

                        <b-form-file
                          v-model="form.icon_light"
                          :state="Boolean(form.icon_light)"
                          accept=".svg"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </div>

                      <div class="form-group">
                        <label for="icon_dark">Icon Dark</label>

                        <b-form-file
                          v-model="form.icon_dark"
                          :state="Boolean(form.icon_dark)"
                          accept=".svg"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </div>

                      <div class="form-group">
                        <label for="name">Name</label>
                        <input
                          v-model="form.name"
                          type="text"
                          class="form-control"
                          id="name"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-md-12 d-flex justify-content-end mt-3">
                      <button
                        v-if="!isSubmit"
                        type="submit"
                        class="btn btn-grey px-4 py-2"
                      >
                        Save Data
                      </button>
                      <button
                        v-else
                        class="
                          btn btn-grey
                          px-4
                          py-2
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                        disabled
                      >
                        <b-spinner class="mx-3" height="1rem"></b-spinner>
                      </button>
                    </div>
                  </div>
                </form>

                <div v-else class="row no-gutters">
                  <div class="col text-center text-black my-3">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Services
import { toastedOption, capitalize } from "@/services/general.js";
import {
  showConstellation,
  editConstellation,
} from "@/services/admin/Constellations.js";

export default {
  name: "AdminConstellationsEdit",
  metaInfo: {
    title: "Admin Constellations - Space Walk",
    description:
      "Space Walk is a website that is intended for people who have interests and talents in astronomy, with some basic knowledge, it is hoped that many people will start to be interested in astronomy.",
  },
  data() {
    return {
      urlImg: process.env.VUE_APP_STORAGE_URL,
      image_light_temporary: null,
      image_dark_temporary: null,
      icon_light_temporary: null,
      icon_dark_temporary: null,
      isLoaded: false,
      isSubmit: false,
      form: {
        image_light: null,
        image_dark: null,
        icon_light: null,
        icon_dark: null,
        name: "",
      },
    };
  },
  mounted() {
    this.constellation();
  },
  methods: {
    constellation() {
      showConstellation(this.$route.params.id).then((response) => {
        const data = response.data.body.data.constellation;

        this.image_light_temporary = data.image_light;
        this.image_dark_temporary = data.image_dark;
        this.icon_light_temporary = data.icon_light;
        this.icon_dark_temporary = data.icon_dark;
        this.form.name = data.name;

        this.isLoaded = true;
      });
    },
    save() {
      const formData = new FormData();
      formData.append(
        "image_light",
        this.form.image_light
          ? this.form.image_light
          : this.image_light_temporary
      );
      formData.append(
        "image_dark",
        this.form.image_dark ? this.form.image_dark : this.image_dark_temporary
      );
      formData.append(
        "icon_light",
        this.form.icon_light ? this.form.icon_light : this.icon_light_temporary
      );
      formData.append(
        "icon_dark",
        this.form.icon_dark ? this.form.icon_dark : this.icon_dark_temporary
      );
      formData.append("name", this.form.name);

      this.isSubmit = true;

      editConstellation(this.$route.params.id, formData)
        .then(() => {
          this.$toasted.success("Edit success", toastedOption);
          this.$router.back();
        })
        .catch((error) => {
          this.isSubmit = false;

          error.response.data.body.errors.forEach((message) => {
            this.$toasted.error(capitalize(message.msg), toastedOption);
          });
        });
    },
  },
};
</script>